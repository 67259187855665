var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newBox"},[_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"leftContentBodyBox",staticStyle:{"bordertop":"0.0625rem dashed #c5c5c5"},on:{"click":function($event){return _vm.fn1(item.id)}}},[_c('img',{staticClass:"leftContentBodyBoxPicture",attrs:{"src":item.image !== ''
          ? item.image
          : _vm.state == 0
          ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
          : _vm.state === 1
          ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
          : _vm.state === 2
          ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
          : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png',"alt":item.image !== ''
          ? item.title
          : _vm.state == 0
          ? '热点咨询'
          : _vm.state == 1
          ? '商标知识'
          : _vm.state == 2
          ? '政策法规'
          : '经典案例'}}),_vm._v(" "),_c('div',{staticClass:"leftContentBodyBoxContent"},[_c('p',{staticClass:"leftContentBodyBoxContentTitle"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',{staticClass:"leftContentBodyBoxContentContetn",domProps:{"innerHTML":_vm._s(_vm.removeHtmlStyle(item.content))}}),_vm._v(" "),_c('div',{staticClass:"leftContentBodyBoxContentIcon"},[_c('div',{staticClass:"leftContentBodyBoxContentIconBox"},[_c('img',{staticClass:"newIcon1",attrs:{"src":require("../../imger/new/pope.png"),"alt":"问标网新闻中心"}}),_vm._v(" "),_c('a',{staticClass:"newWord",attrs:{"href":""}},[_vm._v("作者："+_vm._s(item.author))])]),_vm._v(" "),_c('div',{staticClass:"leftContentBodyBoxContentIconBox"},[_c('img',{staticClass:"newIcon2",attrs:{"src":require("../../imger/new/time.png"),"alt":"问标网新闻中心"}}),_vm._v(" "),_c('a',{staticClass:"newWord",attrs:{"href":""}},[_vm._v(_vm._s(item.create_time.slice(0, 10)))])]),_vm._v(" "),_c('div',{staticClass:"leftContentBodyBoxContentIconBox"},[_c('img',{staticClass:"newIcon3",attrs:{"src":require("../../imger/new/liulan.png"),"alt":"问标网新闻中心"}}),_vm._v(" "),_c('a',{staticClass:"newWord2",attrs:{"href":""}},[_vm._v(_vm._s(item.click)+"人浏览")])])])])])}),_vm._v(" "),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":15,"total":_vm.coath},on:{"prev-click":_vm.prevClick,"next-click":_vm.nextClick,"current-change":_vm.currentChange}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }