<template>
  <!-- 新闻详情页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="4"/>

    <div class="head">
      <div class="titelword">当前位置：<h1 class="weizhi">新闻资讯</h1></div>
    </div>
    <div class="content">
      <div class="left">
        <div class="leftHead">
          <div class="leftHeadCarousel">
            <div class="leftHeadCarouselImg" ref="test">
              <div v-for="(item, index) in newrightData01"
                :key="index" @click="fn1(item.id)">
                
              <img
                
                :src="
                  item.image !==undefined
                    ? item.image
                    : item.cat_name == '热点咨询'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
                    : item.cat_name == '商标知识'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
                    : item.cat_name == '政策法规'
                    ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
                    : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png'
                "
                class="img"
                :alt="item.image !==undefined
                    ? title
                    : item.cat_name == '热点咨询'
              ? '热点咨询'
              : item.cat_name == '商标知识'
              ? '商标知识'
              : item.cat_name == '政策法规'
              ? '政策法规'
              : '经典案例'
              "
              />
              </div>
            </div>
            <div class="leftHeadCarouselWord">
              <a class="leftHeadCarouselWordTitle">{{title}}</a>
              <div class="anniu">
                <div
                  :class="leftState == 0 ? 'an02' : 'an01'"
                  @click="anniu1"
                ></div>
                <div
                  :class="leftState == 500 ? 'an02' : 'an01'"
                  @click="anniu2"
                ></div>
                <div
                  :class="leftState == 1000 ? 'an02' : 'an01'"
                  @click="anniu3"
                ></div>
              </div>
            </div>
          </div>
          <div class="leftHeadTable">
            <div
              class="leftHeadTableBox"
              v-for="(item, index) in newrightData02"
              :key="index"
              @click="fn1(item.id)"
            >
              <div class="leftHeadTableBoxTitle">{{ item.title }}</div>
              <div class="leftHeadTableBoxTime">
                {{ item.create_time.slice(0, 10) }}
              </div>
            </div>
          </div>
        </div>
        <div class="leftContent">
          <div class="leftContentCard">
            <div class="CardBox" v-for="(item, index) in newClass" :key="index">
              <div
                class="leftContentCardBox"
                :class="tabState == index ? 'true' : ''"
                @click="tab(index)"
              >
                {{ item.name }}
              </div>
              <span v-show="index == 3 ? false : true">|</span>
            </div>
          </div>
          <div class="leftContentBody" v-show="tabState == 0">
            <NewBox :arrlength="arrlength" :newsData="newsData" :tabState="tabState"/>
          </div>
          <div class="leftContentBody" v-show="tabState == 1">
            <NewBox :arrlength="arrlength" :newsData="newsData" :tabState="tabState"/>
          </div>
          <div class="leftContentBody" v-show="tabState == 2">
            <NewBox :arrlength="arrlength" :newsData="newsData" :tabState="tabState"/>
          </div>
          <div class="leftContentBody" v-show="tabState == 3">
            <NewBox :arrlength="arrlength" :newsData="newsData" :tabState="tabState"/>
          </div>
        </div>
      </div>
      <div class="right">
        <Phone />
        <Tuijian />
        <Tuijian02 />
      </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import NewBox from "../components/newDetail/newBox.vue";

import Phone from "../components/newDetail/phone.vue";
import Tuijian from "../components/newDetail/tuijian.vue";
import Tuijian02 from "../components/newDetail/tuijian02.vue";
import { keyCode, newIP } from "../API/index";
import { allNew, ClassNew, topNew } from "../API/new";

// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Phone,
    Tuijian,
    Tuijian02,
    NewBox,
  },
  data() {
    return {
      tabState: 0,
      newleftData: [],
      newrightData: [],
      newrightData01: '',
      newrightData02: '',
      title:'',
      leftState: 0,
      newsData: [],
      ip: "",
      newClass: "",
      timer:'',
      arrlength:0,
    };
  },
  async created() {
    this.setTime();
    await this.keyCode();
    await this.allNew();
    await this.topNew();
    await this.ClassNew(this.newClass[0].name);
    this.title=this.newrightData01[0].title
  },
  destroyed(){
    clearInterval(this.timer)
  },
  methods: {
    fn1(data) {
      var UPID = [];
      for (let i = 0; i < this.newrightData.length; i++) {
        let obj = { id: this.newrightData[i].id, name: this.newrightData[i].title };
        UPID[i] = obj;
      }
      sessionStorage.setItem("newsIdArray", JSON.stringify(UPID));
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
    },
    tab(data) {
      this.tabState = data;
      this.ClassNew(this.newClass[data].name);
    },
    //  获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == "" || duan == null || duan == undefined) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    async allNew() {
      const res = await allNew({
        key: sessionStorage.getItem("key"),
        platform:'问标'
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        this.newClass = res.data.list;
      }
    },
    async ClassNew(data) {
      const res = await ClassNew({
        key: sessionStorage.getItem("key"),
        classify: data,
        page: 1,
        page_size: 20,
        platform:'问标'
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        if (res.data.list !== null) {
          this.newsData = res.data.list;
          this.arrlength = res.data.list.length
        } else {
          this.newsData = [];
        }
      }
    },
    async topNew() {
      const res = await topNew({
        key: sessionStorage.getItem("key"),
        platform:'问标'
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        this.newrightData = res.data.data;
        this.newrightData01 = res.data.data.slice(0,3);
        this.newrightData02 = res.data.data.slice(3);
      }
    },
    setTime(){
      this.timer = setInterval(()=>{
        
        if(this.leftState == 0){
          this.anniu2()
          return
        }
        if(this.leftState == 500){
          this.anniu3()
          return
        }
        if(this.leftState == 1000){
          this.anniu1()
          return
        }
      },3000)
    },
    anniu1() {
      let res = this.$refs.test;
      res.style.left = 0;
      this.leftState = 0;
      this.title = this.newrightData01[0].title
    },
    anniu2() {
      let res = this.$refs.test;
      res.style.left = -31.25 + "rem"; /* -500px -> -31.25rem */
      this.leftState = 500;
      this.title = this.newrightData01[1].title
    },
    anniu3() {
      let res = this.$refs.test;
      res.style.left = -62.5 + "rem"; /* -1000px -> -62.5rem */
      this.leftState = 1000;
      this.title = this.newrightData01[2].title
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head {
    width: 74.375rem /* 1190px -> 74.375rem */;
    height: 3.125rem /* 50px -> 3.125rem */;
    font-size: 0.9375rem /* 15px -> 0.9375rem */;
    color: #333333;
    line-height: 3.125rem /* 50px -> 3.125rem */;
    .titelword{
      display: flex;
    }
    .weizhi{
      color: #777777;
      font-size: 0.9375rem /* 15px -> 0.9375rem */;
      font-weight: 400;
    }
  }
  .content {
    width: 75rem /* 1200px -> 75rem */;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.25rem /* 68px -> 4.25rem */;
    .left {
      width: 55.75rem /* 892px -> 55.75rem */;
      padding: 1.25rem /* 20px -> 1.25rem */;
      padding-bottom: 2.8125rem /* 45px -> 2.8125rem */;
      box-sizing: border-box;
      .leftHead {
        width: 100%;
        height: 25rem /* 400px -> 25rem */;
        background-color: #fff;
        display: flex;
        .leftHeadCarousel {
          width: 31.25rem /* 500px -> 31.25rem */;
          height: 100%;
          overflow: hidden;
          position: relative;
          .leftHeadCarouselImg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            transition-duration: 0.5s;
            display: flex;
            cursor: pointer;
            .img {
              width: 31.25rem /* 500px -> 31.25rem */;
              height: 100%;
            }
          }
          .leftHeadCarouselWord {
            width: 100%;
            height: 3.4375rem /* 55px -> 3.4375rem */;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.7);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0.625rem /* 10px -> 0.625rem */;
            box-sizing: border-box;
            color: #c5c5c5;
            font-size: 1rem /* 16px -> 1rem */;
            .leftHeadCarouselWordTitle{
              width: 100%;
              overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
            }
            .anniu {
              width: 6.25rem /* 100px -> 6.25rem */;
              display: flex;
              justify-content: space-evenly;
            }
            .an01 {
              width: 0.75rem /* 12px -> 0.75rem */;
              height: 0.75rem /* 12px -> 0.75rem */;
              background-color: #fff;
              border-radius: 0.375rem /* 6px -> 0.375rem */;
              transition-duration: 0.5s;
            }
            .an02 {
              width: 2.1875rem /* 35px -> 2.1875rem */;
              height: 0.75rem /* 12px -> 0.75rem */;
              background-color: #4d89f1;
              border-radius: 0.375rem /* 6px -> 0.375rem */;
              transition-duration: 0.5s;
            }
          }
        }
        .leftHeadTable {
          flex-grow: 1;
          margin-left: 1.25rem /* 20px -> 1.25rem */;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .leftHeadTableBox {
            width: 21.75rem /* 348px -> 21.75rem */;
            height: 7.0625rem /* 113px -> 7.0625rem */;
            transition-duration: 0.5s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            .leftHeadTableBoxTitle {
              width: 19.9375rem /* 319px -> 19.9375rem */;
              color: #333333;
              font-size: 1rem /* 16px -> 1rem */;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              line-height: 1.25rem /* 20px -> 1.25rem */; //
              height: 3.75rem /* 60px -> 3.75rem */; ///* 2行隐藏就两倍，三行隐藏就三倍*/
            }
            .leftHeadTableBoxTime {
              width: 19.9375rem /* 319px -> 19.9375rem */;
              color: #999999;
              font-size: 0.875rem /* 14px -> 0.875rem */;
            }
          }
          .leftHeadTableBox:hover {
            background-color: #f5f5f5;
            .leftHeadTableBoxTitle{
              color: #4d89f1;
            }
          }
        }
      }
      .leftContent {
        width: 100%;
        margin-top: 1.5625rem /* 25px -> 1.5625rem */;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        .leftContentCard {
          width: 100%;
          height: 3.9375rem /* 63px -> 3.9375rem */;
          border-bottom: 0.0625rem /* 1px -> 0.0625rem */ solid #dddddd;
          display: flex;
          align-items: center;
          color: #dddddd;
          font-size: 1.875rem /* 30px -> 1.875rem */;
          .CardBox {
            display: flex;
            height: 100%;
            align-items: center;
            cursor: pointer;
          }
        }
        .leftContentCardBox {
          width: 6.875rem /* 110px -> 6.875rem */;
          height: 100%;
          box-sizing: border-box;
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #4c4c4c;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .leftContentCardBox:hover{
          color: #4d89f1;
        }
        .true {
          color: #4d89f1;
          border-bottom: 0.1875rem /* 3px -> 0.1875rem */ solid #4d89f1;
        }
      }
      .leftContentBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 2.5rem /* 40px -> 2.5rem */;
        padding-right: 2.5rem /* 40px -> 2.5rem */;
        box-sizing: border-box;
      }
    }
  }
  .right {
    width: 18.4375rem /* 295px -> 18.4375rem */;
  }
}
</style>