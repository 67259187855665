<template>
  <div class="newBox">
    <div
      class="leftContentBodyBox"
      style="bordertop: 0.0625rem dashed #c5c5c5"
      v-for="(item, index) in data"
      :key="index"
      @click="fn1(item.id)"
    >
      <img
        :src="
          item.image !== ''
            ? item.image
            : state == 0
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
            : state === 1
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
            : state === 2
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
            : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png'
        "
        class="leftContentBodyBoxPicture"
        :alt="
          item.image !== ''
            ? item.title
            : state == 0
            ? '热点咨询'
            : state == 1
            ? '商标知识'
            : state == 2
            ? '政策法规'
            : '经典案例'
        "
      />
      <div class="leftContentBodyBoxContent">
        <p class="leftContentBodyBoxContentTitle">{{ item.title }}</p>
        <p
          class="leftContentBodyBoxContentContetn"
          v-html="removeHtmlStyle(item.content)"
        ></p>
        <div class="leftContentBodyBoxContentIcon">
          <div class="leftContentBodyBoxContentIconBox">
            <img
              src="../../imger/new/pope.png"
              class="newIcon1"
              alt="问标网新闻中心"
            />
            <a href="" class="newWord">作者：{{ item.author }}</a>
          </div>
          <div class="leftContentBodyBoxContentIconBox">
            <img
              src="../../imger/new/time.png"
              class="newIcon2"
              alt="问标网新闻中心"
            />
            <a href="" class="newWord">{{ item.create_time.slice(0, 10) }}</a>
          </div>
          <div class="leftContentBodyBoxContentIconBox">
            <img
              src="../../imger/new/liulan.png"
              class="newIcon3"
              alt="问标网新闻中心"
            />
            <a href="" class="newWord2">{{ item.click }}人浏览</a>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="currentChange"
        :page-size="15"
        :total="coath"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  props: ["newsData", "tabState", "arrlength"],
  data() {
    return {
      data: this.newsData,
      state: this.tabState,
      coath: this.arrlength,
    };
  },
  created() {
    // for(let i =0 ; i<=this.data.length ; i++){
    //   this.data.content[i] = this.data.content[i].slice(0,100)
    // }
  },
  watch: {
    newsData(newD, oldD) {
      if (newD) {
        this.data = newD;
      }
    },
    tabState(newD, oldD) {
      if (newD) {
        this.state = newD;
      }
    },
    arrlength(newD, oldD) {
      if (newD) {
        this.coath = newD;
      }
    },
  },
  methods: {
    fn1(data) {
      var UPID = [];
      for (let i = 0; i < this.data.length; i++) {
        let obj = { id: this.data[i].id, name: this.data[i].title };
        UPID[i] = obj;
      }
      sessionStorage.setItem("newsIdArray", JSON.stringify(UPID));
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
    },
    prevClick(data) {
      // console.log("上一页", data);
    },
    nextClick(data) {
      // console.log("下一页", data);
    },
    currentChange(data) {
      // console.log("当前页", data);
    },
    removeHtmlStyle(html) {
      let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "");
      }
      return newHtml;
    },
  },
};
</script>

<style scoped lang="scss">
.newBox {
  width: 100%;
  cursor: pointer;
}
.leftContentBodyBox:hover{
  .leftContentBodyBoxContent{
    .leftContentBodyBoxContentTitle{
      color: #5094f7;
    }
  }
}
.leftContentBodyBox {
  width: 100%;
  height: 11.125rem /* 178px -> 11.125rem */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftContentBodyBoxPicture {
    width: 12.6875rem /* 203px -> 12.6875rem */;
    height: 7.125rem /* 114px -> 7.125rem */;
  }
  .leftContentBodyBoxContent {
    width: 33.75rem /* 540px -> 33.75rem */;
    height: 7.125rem /* 114px -> 7.125rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .leftContentBodyBoxContentTitle {
      height: 3.125rem /* 50px -> 3.125rem */;
      color: #333333;
      font-size: 1.125rem /* 18px -> 1.125rem */;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .leftContentBodyBoxContentContetn {
      height: 2.8125rem /* 45px -> 2.8125rem */;
      color: #666666;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.25rem /* 20px -> 1.25rem */; //
      height: 2.5rem /* 40px -> 2.5rem */; ///* 2行隐藏就两倍，三行隐藏就三倍*/
    }
    .leftContentBodyBoxContentIcon {
      height: 1.5625rem /* 25px -> 1.5625rem */;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftContentBodyBoxContentIconBox {
        height: 100%;
        line-height: 1.5625rem /* 25px -> 1.5625rem */;
        display: flex;
        align-items: center;
        .newIcon1 {
          width: 0.875rem /* 14px -> 0.875rem */;
          height: 1.0625rem /* 17px -> 1.0625rem */;
        }
        .newIcon2 {
          width: 1rem /* 16px -> 1rem */;
          height: 1rem /* 16px -> 1rem */;
        }
        .newIcon3 {
          width: 1.125rem /* 18px -> 1.125rem */;
          height: 0.8125rem /* 13px -> 0.8125rem */;
        }
        .newWord {
          height: 100%;
          margin-left: 0.625rem /* 10px -> 0.625rem */;
          font-size: 0.75rem /* 12px -> 0.75rem */;
          color: #bbbbbb;
        }
        .newWord2 {
          height: 100%;
          margin-left: 0.625rem /* 10px -> 0.625rem */;
          font-size: 0.75rem /* 12px -> 0.75rem */;
          color: #5094f7;
        }
      }
    }
  }
}
.page {
  height: 6.25rem /* 100px -> 6.25rem */;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>